<template>
  <div class="edits">
    <v-container fluid grid-list-xs>

      <v-row>
        <v-col cols="12" md="10" offset-md="1">
          <span class="display-2 font-weight-thin grey--text">Update/Edit</span>
        </v-col>

        <v-col cols="12" md="10" offset-md="1">
          <v-expansion-panels multiple>
            <v-expansion-panel>
              <v-expansion-panel-header ripple :class="expansionPanelHeaderClass">
                <div class="d-flex align-center justify-start">
                  <v-icon class="mr-3" color="white">mdi-account-tie</v-icon>
                  Landlord
                </div>
                <template v-slot:actions>
                  <v-icon color="white">$expand</v-icon>
                </template>
                </v-expansion-panel-header>
              <v-expansion-panel-content>
                <EditLandlords />
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header ripple :class="expansionPanelHeaderClass">
                <div class="d-flex align-center justify-start">
                  <v-icon class="mr-3" color="white">mdi-home</v-icon>
                  Property
                </div>
                <template v-slot:actions>
                  <v-icon color="white">$expand</v-icon>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <EditProperty />
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header ripple :class="expansionPanelHeaderClass">
                <div class="d-flex align-center justify-start">
                  <v-icon class="mr-3" color="white">mdi-file-document-outline</v-icon>
                  Tenancy
                </div>
                <template v-slot:actions>
                  <v-icon color="white">$expand</v-icon>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <EditTenancy />
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header ripple :class="expansionPanelHeaderClass">
                <div class="d-flex align-center justify-start">
                  <v-icon class="mr-3" color="white">mdi-home-account</v-icon>
                  Tenant
                </div>
                <template v-slot:actions>
                  <v-icon color="white">$expand</v-icon>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <EditTenant />
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header ripple :class="expansionPanelHeaderClass">
                <div class="d-flex align-center justify-start">
                  <v-icon class="mr-3" color="white">mdi-tools</v-icon>
                  Contractor
                </div>
                <template v-slot:actions>
                  <v-icon color="white">$expand</v-icon>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <EditContractor />
              </v-expansion-panel-content>
            </v-expansion-panel>

          </v-expansion-panels>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import EditLandlords from '@/components/Edits/EditLandlord.vue'
import EditProperty from '@/components/Edits/EditProperty.vue'
import EditTenancy from '@/components/Edits/EditTenancy.vue'
import EditTenant from '@/components/Edits/EditTenant.vue'
import EditContractor from '@/components/Edits/EditContractor.vue'

export default {
  components: {
    EditLandlords,
    EditProperty,
    EditTenancy,
    EditTenant,
    EditContractor
  },
  data() {
    return {
      expansionPanelHeaderClass: 'secondary lighten-3 white--text'
    }
  },
}
</script>

<style>
.edits {
  min-height: calc(100vh - 165px);
}
</style>