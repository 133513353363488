<template>
  <div>
    <!-- Search Landlord component -->
    <v-row> 
      <v-col cols="12" sm="6">
        <Search
          label="Search Contractor"
          @selectedOID="fetchContractor" 
          @clearItem="clearForm"
          endpoint="contractors"
          myParams="name"
          :clearSearch="clearSearch"
        />
      </v-col>
    </v-row>

    <v-form>
      <v-row>
        <v-col cols="12" sm="6">
          <h3>Details</h3>
          <v-text-field
            color="green"
            v-model="title"
            name="title"
            label="Title"
          ></v-text-field>
          <v-text-field
            color="green"
            v-model="firstName"
            name="firstName"
            label="Name"
          ></v-text-field>
          <v-text-field
            color="green"
            v-model="surname"
            name="surname"
            label="Surname"
          ></v-text-field>
          <v-text-field
            color="green"
            type="email"
            v-model="email"
            name="email"
            label="Email"
            :rules="emailRules"
          ></v-text-field>
          <v-text-field
            color="green"
            type="number"
            v-model="mobile"
            name="mobile"
            label="Mobile"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <h3>Address</h3>
          <v-text-field
            color="green"
            v-model="address1"
            name="address1"
            label="Address"
          ></v-text-field>
          <v-text-field
            color="green"
            v-model="address2"
            name="address2"
            label="Address 2"
          ></v-text-field>
          <v-text-field
            color="green"
            v-model="address3"
            name="address3"
            label="Address 3"
          ></v-text-field>
          <v-text-field
            color="green"
            v-model="address4"
            name="address4"
            label="Address 4"
          ></v-text-field>
          <v-text-field
            color="green"
            v-model="postcode"
            name="postcode"
            label="Postcode"
          ></v-text-field>        
        </v-col>
      </v-row>

      <!-- 2nd Row -->
      <v-row>
        <v-col cols="12" sm="6">
          <h3 class="grey--text">Bank Details</h3>
          <v-text-field
            disabled
            v-model="accountName"
            name="accountName"
            label="Account Name"
          ></v-text-field>
          <v-text-field
            disabled
            v-model="account"
            name="account"
            label="Account No."
          ></v-text-field>
          <v-text-field
            disabled
            v-model="sortCode"
            name="sortCode"
            label="Sort Code"
          ></v-text-field>
          <v-text-field
            disabled
            v-model="statementRef"
            name="statementRef"
            label="Statement Ref"
          ></v-text-field>
        </v-col>

        <Snackbar 
          :snackbarDialog="snackbar"
          :snackbarText="snackbarText"
          :timeout="timeout"
          :snackbarColor="snackbarColor"
          @closeSnackbar="closeSnackbar()"
        />
      </v-row>
      <v-row>
        <v-col cols="12" sm="6">
          <v-row>
            <v-col cols="6" offset="6">
              <v-btn height="40" :loading="loading" block class="my-3" color="success" @click="saveContractor()">Save</v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import axios from 'axios'
import Search from '@/components/CloudSearch/Search'
import Snackbar from '@/components/Snackbars/TheSnackbar'

export default {
  components: {
    Search,
    Snackbar
  },
  data() {
    return {
      snackbar: false,
      snackbarText: '',
      snackbarColor: '',
      timeout: 3000,
      loading: false,
      clearSearch: false,
      searchQuery: '',
      menu1: false,
      selectedContractorID: '',
      title: '',
      firstName: '',
      surname: '',
      email: '',
      mobile: '',
      emailRules: [
        // v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
      address1: '',
      address2: '',
      address3: '',
      address4: '',
      postcode: '',
      accountName: '',
      account: '',
      sortCode: '',
      statementRef: '',
      fee: '',
      date: '',
    }
  },
  methods: {
    closeSnackbar() {
      this.snackbar = false
    },
    dateToString() {
     return this.date ? this.date.toISOString().substr(0, 10) : ''
    }, 
    fetchContractor(id) {
      this.selectedContractorID = id
      axios({
        method: 'GET',
        url: `${this.$store.state.baseURL}/corporate/agentpay/letmcletting/${this.$store.state.user.ClientName}/contractors/${id}`,
        headers: {
          token: this.$store.state.user.LoginToken,
          api_key: this.$store.state.apiKey,
        }
      })
      .then( ({data}) => {
        console.log(data)
        this.title = data.ContractorName.Title
        this.firstName = data.ContractorName.Forename
        this.surname = data.ContractorName.Surname
        this.email = data.Email
        this.mobile = data.Mobile
        this.address1 = data.Address.Address1
        this.address2 = data.Address.Address2
        this.address3 = data.Address.Address3
        this.address4 = data.Address.Address4
        this.postcode = data.Address.Postcode
        this.accountName = data.AccountDetails.AccountName
        this.account = data.AccountDetails.AccountNumber
        this.sortCode = data.AccountDetails.SortCode
        this.statementRef = data.AccountDetails.LegacyBacsReference
      })
    },
    clearForm() {
      this.clearSearch = true
      this.selectedContractorID = ''
      this.title = ''
      this.firstName = ''
      this.surname = ''
      this.email = ''
      this.mobile = ''
      this.address1 = ''
      this.address2 = ''
      this.address3 = ''
      this.address4 = ''
      this.postcode = ''
      this.accountName = ''
      this.account = ''
      this.sortCode = ''
      this.statementRef = ''
    },
    saveContractor() {
      this.loading = true
      const contractorObject = {
        ContractorName: {
          Title: this.title,
          Forename: this.firstName,
          Surname: this.surname
        },
        Email: this.email,
        Mobile: this.mobile,
        AccountDetails: {
          AccountName: this.accountName,
          AccountNumber: this.account,
          SortCode: this.sortCode,
          LegacyBacsReference: this.statementRef
        },
        Address: {
          Address1: this.address1,
          Address2: this.address2,
          Address3: this.address3,
          Address4: this.address4,
          Postcode: this.postcode
        }
      }
      // console.log(contractorObject)
      axios({
        method: 'PATCH',
        url: `${this.$store.state.baseURL}/corporate/agentpay/letmcletting/${this.$store.state.user.ClientName}/contractors/${this.selectedContractorID}`,
        data: contractorObject,
        headers: {
          token: this.$store.state.user.LoginToken,
          api_key: this.$store.state.apiKey,
        }
      })
      .then( () => {
        this.snackbar = true
        this.snackbarText = 'Contractor update successful',
        this.snackbarColor = 'green'
        this.loading = false
        this.fetchContractor(this.selectedContractorID)
      })
      .catch( (err) => {
        this.snackbar = true
        this.snackbarText =  'ERROR: ' + err.response.data,
        this.snackbarColor = 'red'
        this.loading = false
        this.fetchContractor(this.selectedContractorID)
      })
    }
  }
}
</script>

<style scoped>
 form .v-text-field {
    margin: 0 auto;
    /* color: green !important; */
  }
</style>