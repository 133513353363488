<template>
  <div>
    <!-- Search Landlord component -->
    <v-row> 
      <v-col cols="12" sm="6">
        <Search
          label="Search Landlord"
          @selectedOID="fetchLandlord" 
          @clearItem="clearForm"
          endpoint="landlord"
          myParams="name"
          :clearSearch="clearSearch"
          clearable
        />
      </v-col>
    </v-row>

    <v-form>
      <v-row>
        <v-col cols="12" sm="6">
          <h3>Details</h3>
          <v-text-field
            color="green"
            v-model="title"
            name="title"
            label="Title"
          ></v-text-field>
          <v-text-field
            color="green"
            v-model="firstName"
            name="firstName"
            label="Name"
          ></v-text-field>
          <v-text-field
            color="green"
            v-model="surname"
            name="surname"
            label="Surname"
          ></v-text-field>
          <v-text-field
            color="green"
            type="email"
            v-model="email"
            name="email"
            label="Email"
            :rules="emailRules"
          ></v-text-field>
          <v-text-field
            color="green"
            type="number"
            v-model="mobile"
            name="mobile"
            label="Mobile"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <h3>Address</h3>
          <v-text-field
            color="green"
            v-model="address1"
            name="address1"
            label="Address"
          ></v-text-field>
          <v-text-field
            color="green"
            v-model="address2"
            name="address2"
            label="Address 2"
          ></v-text-field>
          <v-text-field
            color="green"
            v-model="address3"
            name="address3"
            label="Address 3"
          ></v-text-field>
          <v-text-field
            color="green"
            v-model="address4"
            name="address4"
            label="Address 4"
          ></v-text-field>
          <v-text-field
            color="green"
            v-model="postcode"
            name="postcode"
            label="Postcode"
          ></v-text-field>        
        </v-col>
      </v-row>

      <!-- 2nd Row -->
      <v-row>
        <v-col cols="12" sm="6">
          <h3 class="grey--text">Bank Details</h3>
          <v-text-field
            disabled
            v-model="accountName"
            name="accountName"
            label="Account Name"
          ></v-text-field>
          <v-text-field
            disabled
            v-model="account"
            name="account"
            label="Account No."
          ></v-text-field>
          <v-text-field
            disabled
            v-model="sortCode"
            name="sortCode"
            label="Sort Code"
          ></v-text-field>
          <v-text-field
            disabled
            v-model="statementRef"
            name="statementRef"
            label="Statement Ref"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <h3>Management</h3>
          <v-text-field
            color="green"
            v-model="fee"
            name="fee"
            label="Fee"
          ></v-text-field>
          <v-menu
            v-model="menu1"
            :close-on-content-click="false"
            max-width="290"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                color="green"
                :value="formattedManagementDate"
                clearable
                label="Date"
                readonly
                v-on="on"
                @click:clear="date = null"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="date"
              @change="menu1 = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" sm="6">
          <h3>NRL Status</h3>
          <v-menu
            v-model="nrlDateMenu"
            :close-on-content-click="false"
            max-width="290"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                color="green"
                :value="formattedNrlDate"
                clearable
                label="Apply from"
                v-on="on"
                @click:clear="NrlStatus.ApplyFrom = null"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="NrlStatus.ApplyFrom"
              @change="nrlDateMenu = false"
            ></v-date-picker>
          </v-menu>
          <v-switch
            color="green"
            v-model="NrlStatus.NrlApplies"
            :label="`NRL applies: ${NrlStatus.NrlApplies}`"
          ></v-switch>
          <v-text-field
            v-if="NrlStatus.NrlApplies"
            color="green"
            v-model="NrlStatus.NrlNumber"
            label="NRL no."
            :rules="nrlNumberRules"
          ></v-text-field>
        </v-col>

        <Snackbar 
          :snackbarDialog="snackbar"
          :snackbarText="snackbarText"
          :timeout="timeout"
          :snackbarColor="snackbarColor"
          @closeSnackbar="closeSnackbar()"
        />

      </v-row>
      <v-row>
        <v-col cols="12" sm="6">
          <v-row>
            <v-col cols="6" offset="6">
              <v-btn :disabled="!NrlStatus.NrlNumber && NrlStatus.NrkApplies" height="40" :loading="loading" block class="my-3" color="success" @click="saveLandlord()">Save</v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      
    </v-form>
  </div>
</template>

<script>
import axios from 'axios'
import Search from '@/components/CloudSearch/Search'
import Snackbar from '@/components/Snackbars/TheSnackbar'
import moment from 'moment/src/moment'

export default {
  name: 'edit-landlord',
  components: {
    Search,
    Snackbar
  },
  data() {
    return {
      snackbar: false,
      snackbarText: '',
      snackbarColor: '',
      timeout: 3000,
      loading: false,
      clearSearch: false,
      searchQuery: '',
      menu1: false,
      selectedLandlordID: '',
      title: '',
      firstName: '',
      surname: '',
      email: '',
      mobile: '',
      emailRules: [
        // v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
      address1: '',
      address2: '',
      address3: '',
      address4: '',
      postcode: '',
      accountName: '',
      account: '',
      sortCode: '',
      statementRef: '',
      fee: '',
      date: '',
      NrlStatus: {
        ApplyFrom: '',
        NrlApplies: false,
        NrlNumber: ''
      },
      nrlDateMenu: false,
      nrlNumberRules: [
        v => !!v || 'Number is required',
        // v => (v && v.length <= 10) || 'Name must be less than 10 characters',
      ],
    }
  },
  methods: {
    closeSnackbar() {
      this.snackbar = false
    },
    dateToString() {
     return this.date ? this.date.toISOString().substr(0, 10) : ''
    }, 
    fetchLandlord(id) {
      this.selectedLandlordID = id
      // console.log('landlord to fetch... ' + id)
      axios({
        method: 'GET',
        url: `${this.$store.state.baseURL}/corporate/agentpay/letmcletting/${this.$store.state.user.ClientName}/landlords/${id}`,
        headers: {
          token: this.$store.state.user.LoginToken,
          api_key: this.$store.state.apiKey,
        }
      })
      .then( ({data}) => {
        console.log(data)
        this.title = data.Name.Title
        this.firstName = data.Name.Forename
        this.surname = data.Name.Surname
        this.email = data.Email
        this.mobile = data.Mobile
        this.address1 = data.Address.Address1
        this.address2 = data.Address.Address2
        this.address3 = data.Address.Address3
        this.address4 = data.Address.Address4
        this.postcode = data.Address.Postcode
        this.accountName = data.BankAccount.AccountName
        this.account = data.BankAccount.AccountNumber
        this.sortCode = data.BankAccount.SortCode
        this.statementRef = data.BankAccount.LegacyBacsReference
        this.fee = data.ManagementFeePercent
        this.date = data.ManagementFeeApplyFrom.substr(0, 10)
        this.NrlStatus.ApplyFrom = data.NrlStatus.ApplyFrom.substr(0, 10)
        this.NrlStatus.NrlApplies = data.NrlStatus.NrlApplies
        this.NrlStatus.NrlNumber = data.NrlStatus.NrlNumber
      })
    },
    clearForm() {
      this.clearSearch = true
      this.selectedLandlordID = ''
      this.title = ''
      this.firstName = ''
      this.surname = ''
      this.email = ''
      this.mobile = ''
      this.address1 = ''
      this.address2 = ''
      this.address3 = ''
      this.address4 = ''
      this.postcode = ''
      this.accountName = ''
      this.account = ''
      this.sortCode = ''
      this.statementRef = ''
      this.fee = ''
      this.date = ''
      this.NrlStatus.ApplyFrom = ''
      this.NrlStatus.NrlApplies = false
      this.NrlStatus.NrlNumber = ''
    },
    saveLandlord() {
      this.loading = true
      const landlordObject = {
        Name: {
          Title: this.title,
          Forename: this.firstName,
          Surname: this.surname
        },
        Email: this.email,
        Mobile: this.mobile,
        BankAccount: {
          AccountName: this.accountName,
          AccountNumber: this.account,
          SortCode: this.sortCode,
          LegacyBacsReference: this.statementRef
        },
        ManagementFeePercent: this.fee,
        DateManagementFee: `${this.date}`,
        Address: {
          Address1: this.address1,
          Address2: this.address2,
          Address3: this.address3,
          Address4: this.address4,
          Postcode: this.postcode
        },
        NrlStatus: {
          ApplyFrom: `${this.NrlStatus.ApplyFrom}T00:01:00`,
          NrlApplies: this.NrlStatus.NrlApplies,
          NrlNumber: this.NrlStatus.NrlNumber,
        }
      }
      console.log(landlordObject)
      axios({
        method: 'PATCH',
        url: `${this.$store.state.baseURL}/corporate/agentpay/letmcletting/${this.$store.state.user.ClientName}/landlords/${this.selectedLandlordID}`,
        data: landlordObject,
        headers: {
          token: this.$store.state.user.LoginToken,
          api_key: this.$store.state.apiKey,
        }
      })
      .then( () => {
        this.snackbar = true
        this.snackbarText = 'Landlord update successful',
        this.snackbarColor = 'green'
        this.loading = false
        this.fetchLandlord(this.selectedLandlordID)
        this.clearForm()
      })
      .catch( (err) => {
        this.snackbar = true
        this.snackbarText =  'ERROR: ' + err.response.data,
        this.snackbarColor = 'red'
        this.loading = false
        this.fetchLandlord(this.selectedLandlordID)
      })
    }
  },
  computed: {
    formattedNrlDate() {
      return this.NrlStatus.ApplyFrom ? moment(this.NrlStatus.ApplyFrom).format('DD/MM/YYYY') : ''
    },
    formattedManagementDate() {
      return this.date ? moment(this.date).format('DD/MM/YYYY') : ''
    }
  }
}
</script>

<style scoped>
 form .v-text-field {
    margin: 0 auto;
    /* color: green !important; */
  }
</style>