<template>
  <div>
    <!-- Search Property component -->
    <v-row>
      <v-col cols="12" sm="6">
        <Search 
          label="Search Property"
          @selectedOID="fetchProperty" 
          endpoint="property"
          myParams="address"
        />
      </v-col>
    </v-row>

    <v-form>
      <v-row>
        <v-col cols="12" sm="6">
          <h3>Details</h3>
          <v-text-field
            color="green"
            v-model="houseNumber"
            name="houseNumber"
            label="House Name/No"
          ></v-text-field>
          <v-text-field
            color="green"
            v-model="address1"
            name="address1"
            label="Address 1"
          ></v-text-field>
          <v-text-field
            color="green"
            v-model="address2"
            name="address2"
            label="Address 2"
          ></v-text-field>
          <v-text-field
            color="green"
            v-model="address3"
            name="address2"
            label="Address 3"
          ></v-text-field>
          <v-text-field
            color="green"
            v-model="address4"
            name="address4"
            label="Address 4"
          ></v-text-field>
          <v-text-field
            color="green"
            v-model="postcode"
            name="postcode"
            label="Postcode"
          ></v-text-field>
          <v-row>
            <v-col cols="6" offset="6">
              <v-btn height="40" :loading="loading" block class="my-3" color="success" @click="saveProperty()">Save</v-btn>
              <!-- <v-btn block color="red" outlined text @click="clearForm()">Clear Form</v-btn> -->
            </v-col>
          </v-row>
          
          <!-- Snackbar indicating API request responses -->
          <Snackbar 
            :snackbarDialog="snackbar"
            :snackbarText="snackbarText"
            :timeout="timeout"
            :snackbarColor="snackbarColor"
            @closeSnackbar="closeSnackbar()"
          />
        </v-col>
      </v-row>
    </v-form>

  </div>
</template>

<script>
import axios from 'axios'
import Snackbar from '@/components/Snackbars/TheSnackbar'
import Search from '@/components/CloudSearch/Search'

export default {
  components: {
    Snackbar,
    Search
  },
  data() {
    return {
      snackbar: false,
      snackbarText: '',
      snackbarColor: '',
      timeout: 3000,
      loading: false, 
      selectedPropertyID: null,
      houseNumber: '',
      address1: '',
      address2: '',
      address3: '',
      address4: '',
      postcode: '',
    }
  },
  methods: {
    closeSnackbar() {
      this.snackbar = false
    },
    clearForm() {
      this.selectedPropertyID = null
      this.houseNumber = ''
      this.address1 = ''
      this.address2 = ''
      this.address3 = ''
      this.address4 = ''
      this.postcode = ''
    },
    fetchProperty(id) {
      this.selectedPropertyID = id
      axios({
        method: 'GET',
        url: `${this.$store.state.baseURL}/corporate/agentpay/letmcletting/${this.$store.state.user.ClientName}/properties/${id}`,
        headers: {
          token: this.$store.state.user.LoginToken,
          api_key: this.$store.state.apiKey,
        }
      })
      .then( ({data}) => {
        console.log(data)
        this.houseNumber = data.HouseNumber
        this.address1 = data.Address.Address1
        this.address2 = data.Address.Address2
        this.address3 = data.Address.Address3
        this.address4 = data.Address.Address4
        this.postcode = data.Address.Postcode
      })
    },
    saveProperty() {
      this.loading = true
      const propertyObject = {
        HouseNumber: this.home,
        Address: {
          Address1: this.address1,
          Address2: this.address2,
          Address3: this.address3,
          Address4: this.address4,
          Postcode: this.postcode
        }
      }
      console.log(propertyObject)
      axios({
        method: 'PATCH',
        url: `${this.$store.state.baseURL}/corporate/agentpay/letmcletting/${this.$store.state.user.ClientName}/properties/${this.selectedPropertyID}`,
        data: propertyObject,
        headers: {
          token: this.$store.state.user.LoginToken,
          api_key: this.$store.state.apiKey,
        }
      })
      .then( () => {
        this.snackbar = true
        this.snackbarText = 'Property update successful'
        this.snackbarColor = 'green'
        this.loading = false
        this.fetchProperty(this.selectedPropertyID)
      })
      .catch( (err) => {
        this.snackbar = true
        this.snackbarText = 'ERROR: ' + err.response.data,
        this.snackbarColor = 'red'
        this.loading = false
        this.fetchProperty(this.selectedPropertyID)
      })
    }
  }
}
</script>

<style>
.v-text-field {
  margin: 0 auto;
}
</style>